import styled from "styled-components"

import { Text as BaseText } from "../UI"
import { Title as BaseTitle } from "../UI"

export const Wrapper = styled.section`
  width: 100vw;

  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 1280px;

  padding: 80px 40px 80px 40px;

  @media (max-width: 550px) {
    padding: 48px 20px 48px 20px;
    padding-bottom: 0px;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 880px;

  display: flex;
  margin-top: 26px;

  /* margin-bottom: 100px; */

  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 26px;
  }
`

export const Column = styled.div`
  width: 100%;

  &:first-child {
    width: 240px;
  }

  @media (max-width: 550px) {
    h3 {
      padding-bottom: 26px;
    }
  }
`

export const Team = styled.div`
  display: block;
  width: calc(100% - 72px);
  margin-left: 72px;
  margin-top: 64px;

  @media (max-width: 550px) {
    width: 100%;
    margin-left: 0px;
  }
`

export const Text = styled(BaseText)`
  @media (min-width: 550px) {
    margin-left: 16px;
  }
`

export const Role = styled(Text)`
  opacity: 0.5;
  margin-top: 0;
`

export const Title = styled(BaseTitle)`
  position: relative;
  font-weight: 500;
  padding-right: 10px;

  &:after {
    content: "";
    position: absolute;
    display: block;

    top: calc(50% - 1px);
    left: -72px;

    height: 2px;
    width: 48px;

    background-color: #000;
  }
`
