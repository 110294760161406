import React from "react"

import * as S from "./style"
import { Title, Text } from "../UI/Type"

import { FormattedMessage } from 'react-intl'

const Legal = () => {
  return (
    <S.Wrapper>
      <S.Container>
      <S.Content>
      <Text medium>
        <FormattedMessage id="legalPage.adamantLine1" />
        <br />
        <FormattedMessage id="legalPage.adamantLine2" />
        <br/><br/>
      </Text>
      </S.Content>
      <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.companyIdentification.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
            <FormattedMessage id="legalPage.companyIdentification.text1" />
            <br/>
            <FormattedMessage id="legalPage.companyIdentification.text2" />
            <br/>
            <FormattedMessage id="legalPage.companyIdentification.text3" />
            <br/>
            <FormattedMessage id="legalPage.companyIdentification.text4" />
            <br/>
            <FormattedMessage id="legalPage.companyIdentification.text5" />
          </S.Text>
        </S.Column>
        </S.Content>
      <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.purpose.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
          <FormattedMessage id="legalPage.purpose.text1" />
          <br />
          <FormattedMessage id="legalPage.purpose.text2" />
          <br />
          <FormattedMessage id="legalPage.purpose.text3" />
          <br />
          <FormattedMessage id="legalPage.purpose.text4" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.personalData.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
            <FormattedMessage id="legalPage.personalData.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.consent.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
            <FormattedMessage id="legalPage.consent.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.preservationPeriod.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
          <FormattedMessage id="legalPage.preservationPeriod.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.sharingPD.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
          <FormattedMessage id="legalPage.sharingPD.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.dataOwnerRights.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
            <FormattedMessage id="legalPage.dataOwnerRights.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.linksToPartner.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
            <FormattedMessage id="legalPage.linksToPartner.text1" />
          </S.Text>
        </S.Column>
        </S.Content>
        <S.Content>
        <S.Column>
          <Title>
            <FormattedMessage id="legalPage.cookies.title" />
          </Title>
        </S.Column>
        <S.Column>
          <S.Text medium>
          <FormattedMessage id="legalPage.cookies.text1" />
           <br />
           <br />
           <FormattedMessage id="legalPage.cookies.text2" />
          <br />
          <FormattedMessage id="legalPage.cookies.text3" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text4" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text5" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text6" />
          <br />
          <FormattedMessage id="legalPage.cookies.text7" />
          <br />
          <FormattedMessage id="legalPage.cookies.text8" />
          <br />
          <FormattedMessage id="legalPage.cookies.text9" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text10" />
          <br />
          <FormattedMessage id="legalPage.cookies.text11" />
          <br />
          <FormattedMessage id="legalPage.cookies.text12" />
          <br />
          <FormattedMessage id="legalPage.cookies.text13" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text14" />
          <br />
          <FormattedMessage id="legalPage.cookies.text15" />
          <br />
          <FormattedMessage id="legalPage.cookies.text16" />

          <br /><br />
          <FormattedMessage id="legalPage.cookies.text17" />
          </S.Text>
        </S.Column>
      </S.Content>
      <S.Content>
      <S.Column>
        <Title>
          <FormattedMessage id="legalPage.changes.title" />
        </Title>
      </S.Column>
      <S.Column>
        <S.Text medium>
        <FormattedMessage id="legalPage.changes.text1" />
        <br/><br/>
        <FormattedMessage id="legalPage.changes.text2" />
        </S.Text>
      </S.Column>
      </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default Legal
