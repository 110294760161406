import React from "react"
import Layout from "../components/Layout/Layout"
import Legal from "../components/Legal/Legal"

const IndexPage = ({ pageContext: { locale } }) => {
  const metadata = {
    title: "legalPage.metadata.title",
    description: "legalPage.metadata.description",
  }

  return (
    <Layout locale={locale} metadata={metadata}>
      <Legal />
    </Layout>
  )
}

export default IndexPage
